/*.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {*/
/*  border: 0;*/
/*}*/

.MuiInputBase-root .select-icon {
  position: absolute;
  height: 36px;
  right: 10px;
}

/*.MuiInputBase-root .opened {*/
/*  transform: rotate(180deg);*/
/*}*/

/*.MuiPaper-root.MuiMenu-paper {*/
/*  padding-top: 50px;*/
/*  margin-top: -50px;*/
/*  border-radius: 28px;*/
/*}*/

/*.MuiInputBase-root.MuiOutlinedInput-root {*/
/*  display: block;*/
/*}*/

/*.MuiInputBase-root.MuiOutlinedInput-root > div:first-child,*/
/*.MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-icon {*/
/*  display: none;*/
/*}*/

.MuiMenu-root.MuiModal-root {
  z-index: 0;
}
